import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Row, Col } from "antd";
import {
  ServiceStyled,
  Title,
  SubTitle,
  ServiceItem,
  ServiceHolder,
  ServiceItemHolder,
  ServiceTitleOrder,
  ServiceTitle,
} from "./Service.style";
import SectionContent from "../../Elements/SectionContent";

export interface Query {
  allContentfulService: {
    nodes: {
      title: string;
      serviceItem: string[];
      order: number;
    }[];
  };
}

const query = graphql`
  query {
    allContentfulService {
      nodes {
        title
        serviceItem
        order
      }
    }
  }
`;

export default () => {
  const {
    allContentfulService: { nodes },
  }: Query = useStaticQuery(query);
  const serviceItems = nodes
    .sort((a, b) => a.order - b.order)
    .map((item) => (
      <Col key={item.title} span={24} md={{ span: 6 }}>
        <ServiceHolder>
          <ServiceTitleOrder>{`0${item.order}`}</ServiceTitleOrder>
          <ServiceTitle>{item.title}</ServiceTitle>
          <ServiceItemHolder>
            {item.serviceItem.map((serviceItem) => (
              <ServiceItem key={serviceItem}>{serviceItem}</ServiceItem>
            ))}
          </ServiceItemHolder>
        </ServiceHolder>
      </Col>
    ));

  return (
    <ServiceStyled>
      <SectionContent>
        <SubTitle>The Digital Innovation Company</SubTitle>
        <Title>What you can expect</Title>
        <Row type="flex" justify="space-between">
          {serviceItems}
        </Row>
      </SectionContent>
    </ServiceStyled>
  );
};
