import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Title from '../../Elements/Title';
import Description from '../../Elements/Description';
import Button from '../../Elements/Button';
import SectionContent from '../../Elements/SectionContent';
import { MeetingHolder, ButtonHolder } from './Meeting.style';

export interface Query {
  allContentfulMeeting: {
    edges: {
      node: {
        title: string;
        description: string;
        bookButton: string;
        bookLink: string;
      };
    }[];
  };
}

const query = graphql`
  query {
    allContentfulMeeting {
      edges {
        node {
          title
          description
          bookButton
          bookLink
        }
      }
    }
  }
`;

export default () => {
  const {
    allContentfulMeeting: { edges }
  }: Query = useStaticQuery(query);
  const meetingItem = edges.length && edges[0].node;

  return (
    <SectionContent>
      <MeetingHolder>
        <div>
          <Title>{meetingItem.title}</Title>
          <Description>{meetingItem.description}</Description>
        </div>
        <ButtonHolder>
          <a href={meetingItem.bookLink} target="_blank">
            <Button>{meetingItem.bookButton}</Button>
          </a>
        </ButtonHolder>
      </MeetingHolder>
    </SectionContent>
  );
};
