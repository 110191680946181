import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import Title from "../../Elements/Title";
import Input from "../../Elements/Input";
import InputText from "../../Elements/InputText";
import Button from "../../Elements/Button";
import Select from "../../Elements/Select";
import Description from "../../Elements/Description";
import SectionContent from "../../Elements/SectionContent";
import {
  ContactHolder,
  ContactForm,
  ContactOptions,
  ContactAddress,
} from "./Contact.style";

export interface IAwards {
  allContentfulContact: {
    edges: {
      node: {
        title: string;
        locationTitle: string;
        location: {
          lon: number;
          lat: number;
        };
        contactTitle: string;
        address: string;
        contactOptions: {
          title: string;
          value: string;
        }[];
      };
    }[];
  };
}

interface State {
  contactOption: string;
}

class Contact extends Component<IAwards, State> {
  constructor(props: IAwards) {
    super(props);

    this.state = {
      contactOption: "",
    };

    this.setContactOption = this.setContactOption.bind(this);
  }

  setContactOption(e: React.FormEvent<HTMLSelectElement>) {
    this.setState({ contactOption: e.currentTarget.value });
  }

  render() {
    const {
      allContentfulContact: { edges },
    } = this.props;
    const { contactOption } = this.state;
    const contactItem = edges.length && edges[0].node;

    return (
      <SectionContent>
        <ContactHolder>
          <ContactAddress>
            <div>
              <Title>{contactItem.locationTitle}</Title>
              <Description>{contactItem.address}</Description>
            </div>
            <a
              href={`https://www.google.com/maps/dir//${contactItem.location.lat},${contactItem.location.lon}`}
              target="_blank"
            >
              <Button>GET DIRECTIONS</Button>
            </a>
          </ContactAddress>
        </ContactHolder>
      </SectionContent>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulContact {
          edges {
            node {
              title
              locationTitle
              location {
                lon
                lat
              }
              address
              contactTitle
              contactOptions {
                title
                value
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Contact allContentfulContact={data.allContentfulContact} />
    )}
  />
);
