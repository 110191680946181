import styled from 'styled-components';
import colors from '../../../constants/style/colors';

export const MeetingHolder = styled.section`
  padding: 30px 30px 90px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: ${colors.lightGrey};

  :hover {
    color: ${colors.white};
    background-color: ${colors.yellow};

    h3,
    button {
      color: ${colors.white};
      border-color: ${colors.white};
    }
  }

  @media (max-width: 813px) {
    padding-bottom: 30px;
  }
`;

export const ButtonHolder = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  width: 100%;

  a {
    width: 60%;
  }

  button {
    width: 100%;

    &:hover {
      color: ${colors.grey};
      border: 2px solid ${colors.grey};
    }
  }
`;
