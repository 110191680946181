import React from "react";
import { Row, Col } from "antd";
import { StocksStyled, WidgetHolder, Title } from "./Stocks.style";
import SectionContent from "../../Elements/SectionContent";
import TradingViewWidget from "react-tradingview-widget";
import StockWidget from "../../Elements/StockWidget";

export default () => {
  return (
    <StocksStyled>
      <SectionContent>
        <Title>Stocks</Title>
        <WidgetHolder>
          <Row gutter={16}>
            <StockWidget symbol="NASDAQ:AAPL" title="Apple" />
            <StockWidget symbol="NASDAQ:AMZN" title="Amazon" />
            <StockWidget symbol="NASDAQ:INTC" title="Intel" />
            <StockWidget symbol="NASDAQ:TSLA" title="Tesla" />
          </Row>
        </WidgetHolder>
      </SectionContent>
    </StocksStyled>
  );
};
