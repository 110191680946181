import React from "react";
// @ts-ignore
import { Element } from "react-scroll";
import Layout from "../components/Layout";
import Intro from "../components/Content/Intro";
// import Strategy from '../components/Content/Strategy';
import Service from "../components/Content/Service";
import Ecosystem from "../components/Content/Ecosystem";
import Innovation from "../components/Content/Innovation";
// import Industries from '../components/Content/Industries';
import Meeting from "../components/Content/Meeting";
import Stocks from "../components/Content/Stocks";
import Contact from "../components/Content/Contact";
import Result from "../components/Content/Result";
import Partnerships from "../components/Content/Partnerships";

const IndexPage = () => (
  <Layout isLandingPage>
    <Element name="#INTRO">
      <Intro />
    </Element>
    <Element name="#SERVICE">
      <Service />
    </Element>
    <Element name="#ECOSYSTEM">
      <Ecosystem />
    </Element>
    <Element name="#RESULT">
      <Result />
    </Element>
    <Element name="#INNOVATION">
      <Innovation />
    </Element>
    <Element name="#PARTNERSHIPS">
      <Partnerships />
    </Element>
    <Element name="#STOCKS">
      <Stocks />
    </Element>
    <Element name="#GET GOING">
      <Meeting />
      <Contact />
    </Element>
    {/* <Element name="#INSTAGRAM">
      <Instagram />
    </Element> */}
  </Layout>
);

export default IndexPage;
