import React from "react";
import { Col } from "antd";
import { Title } from "./StockWidget.style";
import TradingViewWidget from "react-tradingview-widget";

interface IStockWidget {
  symbol: string;
  title: string;
}

export default ({ symbol, title }: IStockWidget) => {
  return (
    <Col
      span={24}
      sm={{ span: 12 }}
      lg={{ span: 8 }}
      xxl={{ span: 6 }}
      style={{ marginBottom: 100 }}
    >
      <Title>{title}</Title>
      <TradingViewWidget symbol={symbol} autosize />
    </Col>
  );
};
