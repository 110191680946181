import styled from "styled-components";
import colors from "../../../constants/style/colors";

export const ServiceStyled = styled.div`
  padding: 120px 0;
  background-color: ${colors.black};
  color: ${colors.white};

  section {
    background-color: ${colors.black};
  }
`;
export const Title = styled.h1`
  color: ${colors.white};
  font-weight: bold;
`;
export const SubTitle = styled.h4`
  color: ${colors.white};
`;

export const ServiceHolder = styled.div`
  color: ${colors.white};
`;
export const ServiceTitle = styled.h3`
  color: ${colors.white};
`;
export const ServiceTitleOrder = styled.div`
  color: ${colors.yellow};
`;
export const ServiceItemHolder = styled.ul``;
export const ServiceItem = styled.li`
  font-size: 16px;
`;
