import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Title from "../../Elements/Title";
import Description from "../../Elements/Description";
import SectionContent from "../../Elements/SectionContent";
import { IntroHolder, ItemsHolder, WallpaperHolder } from "./Intro.style";
import Img from "gatsby-image";

export interface Query {
  allContentfulIntro: {
    nodes: {
      title: string;
      description: string;
      wallpaper?: {
        file: {
          contentType: "image/jpeg" | "video/mp4";
          url: string;
        };
        fluid: {
          src: string;
          aspectRatio: number;
          srcSet: string;
          sizes: string;
        };
      };
      order: number;
    }[];
  };
}

const query = graphql`
  query {
    allContentfulIntro {
      nodes {
        title
        description
        wallpaper {
          file {
            contentType
            url
          }
          fluid(toFormat: JPG, quality: 90, maxWidth: 2500) {
            src
            aspectRatio
            srcSet
            sizes
          }
        }
        order
      }
    }
  }
`;

export default () => {
  const {
    allContentfulIntro: { nodes },
  }: Query = useStaticQuery(query);
  const mainItem = nodes.find((node) => node.order === 1);

  const Wallpaper = () => (
    <WallpaperHolder id="WALLPAPER">
      {mainItem && mainItem.wallpaper && (
        <>
          {mainItem.wallpaper.file.contentType.indexOf("image") !== -1 && (
            <Img fluid={mainItem.wallpaper.fluid} />
          )}
          {mainItem.wallpaper.file.contentType === "video/mp4" && (
            <video playsInline autoPlay muted loop>
              <source src={mainItem.wallpaper.file.url} type="video/mp4" />
            </video>
          )}
        </>
      )}
    </WallpaperHolder>
  );

  const IntroItems = () => (
    <ItemsHolder>
      <SectionContent>
        {nodes
          .sort((a, b) => a.order - b.order)
          .map((item) => (
            <div key={item.order}>
              <Title>{item.title}</Title>
              <Description>{item.description}</Description>
            </div>
          ))}
      </SectionContent>
    </ItemsHolder>
  );

  return (
    <IntroHolder>
      <Wallpaper />
      <IntroItems />
    </IntroHolder>
  );
};
