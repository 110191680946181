import styled from "styled-components";
import colors from "../../../constants/style/colors";

export const ContactHolder = styled.section`
  margin-top: -20px;
  margin-bottom: 100px;
  overflow: hidden;
  background-color: ${colors.lightGrey};

  @media (max-width: 813px) {
    padding-bottom: 30px;
  }
`;

export const ContactForm = styled.form`
  padding: 30px 60px 100px;
  display: inline-block;
  width: 50%;
  vertical-align: top;

  :hover {
    color: ${colors.white};
    background-color: ${colors.yellow};

    input,
    textarea,
    button {
      color: ${colors.white};
      border-color: ${colors.white};

      ::placeholder {
        color: ${colors.white};
      }

      &:hover {
        color: ${colors.grey};
        border: 2px solid ${colors.grey};

        ::placeholder {
          color: ${colors.grey};
        }
      }
    }
  }

  input {
    margin-bottom: 20px;
  }

  button {
    float: right;
    margin: 20px 0;
  }

  @media (max-width: 813px) {
    width: 100%;

    padding: 30px;
  }
`;
export const ContactOptions = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  width: 50%;

  :hover {
    color: ${colors.white};
    background-color: ${colors.yellow};

    select,
    button {
      color: ${colors.white};
      border-color: ${colors.white};

      &:hover {
        color: ${colors.grey};
        border: 2px solid ${colors.grey};
      }
    }
  }

  h2 {
    margin-bottom: 32px;
  }

  select,
  a {
    width: 60%;
  }

  button {
    margin-top: 30px;
    width: 100%;
    color: ${colors.grey};
    border: 2px solid ${colors.grey};
  }

  @media (max-width: 813px) {
    width: 100%;
  }
`;

export const ContactAddress = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 30px 90px;

  :hover {
    color: ${colors.white};
    background-color: ${colors.yellow};

    h3,
    button {
      color: ${colors.white};
      border-color: ${colors.white};
    }
  }

  a {
    width: 60%;
  }

  button {
    margin-top: 40px;
    width: 100%;

    &:hover {
      color: ${colors.grey};
      border: 2px solid ${colors.grey};
    }
  }
`;
