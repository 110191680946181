import styled from "styled-components";
import colors from "../../../constants/style/colors";

export const StocksStyled = styled.div`
  @media (min-width: 576px) {
    height: 750px;
  }
  @media (min-width: 992px) {
    height: 430px;
  }
  padding: 120px 0;
  overflow: hidden;
  background-color: ${colors.white};
  height: 1350px;
  margin-bottom: 100px;
`;

export const WidgetHolder = styled.div`
  @media (min-width: 992px) {
    height: 800px;
  }
  height: 1500px;
`;
export const Title = styled.h1`
  font-weight: bold;
`;
