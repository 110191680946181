import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { SectionTitleHolder, BrandHolder, Brand } from "./Partnerships.style";
import Title from "../../Elements/Title";
import SectionContent from "../../Elements/SectionContent";

export interface Query {
  allContentfulPartnerships: {
    edges: {
      node: {
        link: string;
        brand: {
          file: {
            url: string;
          };
        };
        order: number;
      };
    }[];
  };
}

const query = graphql`
  query {
    allContentfulPartnerships {
      edges {
        node {
          link
          brand {
            file {
              url
            }
          }
          order
        }
      }
    }
  }
`;

export default () => {
  const {
    allContentfulPartnerships: { edges },
  }: Query = useStaticQuery(query);
  const intoItems = edges
    .sort((a, b) => a.node.order - b.node.order)
    .map((item) => (
      <Brand key={item.node.order}>
        <img src={item.node.brand.file.url} alt={item.node.link} />
      </Brand>
    ));

  return (
    <SectionContent>
      <SectionTitleHolder>
        <Title>PARTNERSHIPS</Title>
      </SectionTitleHolder>
      <BrandHolder>{intoItems}</BrandHolder>
    </SectionContent>
  );
};
